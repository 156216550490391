<template>
  <div>
    <loading v-if="cargando" />
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="sitio">Sitio</label>
          <v-select
            :class="[
              $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
              $store.getters.getDarkMode ? 'dark-vselect' : '',
            ]"
            v-model="sitio"
            placeholder="Sitio"
            label="nombre"
            class="form-control form-control-sm p-0"
            :options="listasForms.sitios"
            @input="selectSitio()"
          ></v-select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <br />
          <button
            v-show="!$v.form.$invalid"
            type="button"
            @click="cargar()"
            class="btn btn-success"
            data-html="true"
            title="Guardar punto de gestion"
            data-toggle="tooltip"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div id="tabla puntos">
      <div class="card-body p-0">
        <div class="col-md-8 form-group">
          <table
            id="tabla-rutas"
            class="table table-bordered table-striped table-hover table-sm"
          >
            <thead>
              <tr>
                <th class="text-center">Sitio</th>
                <th class="text-center">Servicios</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="rutaSitio in rutasSitio" :key="rutaSitio.id">
                <td>
                  <span>
                    {{ rutaSitio.n_sitio }}
                  </span>
                </td>
                <td class="col-sm-2 text-right">
                  <span>
                    <!-- Botón para borrar -->
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="destroy(rutaSitio.id)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TifRutasSitio",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      form: { sitio_id: null },
      form2: { sitio_id: null },
      idActual: null,
      save: {},
      flag: null,
      idAnterior: null,
      idSuperior: null,
      sitio: [],
      sitio2: [],
      tif_ruta_id: null,
      rutasSitio: {},
      listasForms: {
        sitios: [],
        sitios2: [],
      },
      routes: 0,
    };
  },
  validations: {
    form: {
      sitio_id: {
        required,
      },
    },
    form2: {
      sitio_id: {
        required,
      },
    },
  },
  methods: {
    getIndex() {
      this.form.tif_ruta_id = this.$route.params.id;
      this.tif_ruta_id = this.form.tif_ruta_id;
      axios
        .get("/api/tif/rutas/show/" + this.form.tif_ruta_id)
        .then((response) => {
          this.rutasSitio = response.data;
          this.routes = response.data.length;
          this.cargando = false;
        });
    },

    cargar() {
      this.cargando = true;
      this.save = {
        tif_sitio_id: this.sitio.id,
        tif_ruta_id: this.tif_ruta_id,
      };
      axios
        .get("/api/tif/rutas/validar", {
          params: {
            tif_sitio_id: this.sitio.id,
            tif_ruta_id: this.tif_ruta_id,
          },
        })
        .then((response) => {
          if (response.data == 0) {
            axios({
              method: "POST",
              url: "/api/tif/rutas/saveSitios",
              data: this.save,
            })
              .then((response) => {
                this.getIndex();
                this.$swal({
                  icon: "success",
                  title: "La Ruta se guardo exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              })
              .catch((e) => {
                this.cargando = false;
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error, vuelva a intentarlo..." + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          } else {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ya existe este sitio para esta ruta",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },
    validar() {},

    buscarSitios() {
      let me = this;
      me.listasForms.sitios = [];
      var url = "api/tif/sitios/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.sitios = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios2() {
      let me = this;
      me.listasForms.sitios2 = [];
      var url = "api/tif/sitios/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.sitios2 = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectSitio() {
      this.form.sitio_id = "";
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    selectSitio2() {
      this.form2.sitio_id = "";
      if (this.sitio2) {
        this.form2.sitio_id = this.sitio2.id;
      }
    },

    destroy: function(ruta_id) {
      this.$swal({
        title: "Está seguro de quitar el este sitio de la Ruta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        this.cargando = true;
        axios.delete("/api/tif/rutas/destroySitio/" + ruta_id).then(() => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Se eliminó el sitio exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.getIndex();
        });
      });
    },
  },

  mounted() {
    this.getIndex();
    this.buscarSitios();
    this.buscarSitios2();
  },
};
</script>
